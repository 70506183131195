.root
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 30px
  height: 100vh
  background-color: #fff

.textContent
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 10px

.caption
  text-align: center
  font-size: 20px
  font-weight: 500
  color: var(--secondary)

.text
  color: var(--secondary, #5D5D5D)
  text-align: center
  font-size: 15px
  font-weight: 400
