.root
  cursor: pointer
  position: relative
  flex: 1 0 auto
  background-color: var(--text-primary-dark, #000)
  font-size: 0

.wrapper
  position: relative
  background-repeat: no-repeat
  background-size: cover
  background-position: center

.img
  object-fit: contain
  backdrop-filter: blur(10px)

.imgBlured
  filter: blur(10px)

.premium
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-align: center
  width: 80%

.premiumTitle
  font-size: 17px
  font-weight: bold
  color: var(--text-primary-light, #fff)
  margin-bottom: 5px

.premiumDescription
  font-size: 12px
  line-height: 1.25
  color: var(--text-primary-light, #fff)
  margin-bottom: 20px

.premiumButton
  width: 185px
  border-radius: 10px

.desktopPremiumButton
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  margin: 0
  width: 100%
  height: 100%

.desktopPremiumTitle
  font-weight: 600
  font-size: 12px
  line-height: 14px
  text-align: center
  text-transform: uppercase
  color: var(--text-primary-light, #fff)

.overlay
  position: absolute
  top: 0
  left: 0
  z-index: 1
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  color: var(--text-primary-light, #fff)
  font-size: 22px
  font-weight: 600
  background-color: #00000080
  border-bottom-right-radius: 15px

.bluer
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #00000026
  background-image: url('../../../../assets/icons/chat/eye-hide.svg')
  background-position: center
  background-repeat: no-repeat
  backdrop-filter: blur(3px)

.withoutIcon
  background-image: none
