@import 'styles/_variables'

.profileList
  display: flex
  flex-direction: column

.profileItem
  display: flex
  align-items: center
  cursor: pointer
  gap: 10px
  padding: 10px
  border-radius: 10px

.profileItemActive
  background: var(--bg-primary-light, #F5F5F5)

.name
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 500

.notification
  min-width: 16px
  height: 16px
  padding: 3px
  border-radius: 8px
  background: var(--danger, #F00)
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-primary-light, #fff)
  font-size: 10px
  font-weight: 600
  margin-left: auto
