@import 'styles/_variables'

.root
  flex-grow: 1
  flex-shrink: 0.4
  height: 100%
  max-height: 40%
  display: flex
  flex-direction: column
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary, #FFFFFF)
  overflow-y: auto

.withTransition
  transition: max-height 0.2s ease-out

.showMore
  max-height: 60%

.maxHeight100
  max-height: 100%

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 20px 0
  margin: 0 20px
  border-bottom: 1px solid var(--bg-secondary, #E9E9E9)

.title
  display: flex
  align-items: center
  color: var(--text-secondary, #5D5D5D)
  font-size: 15px
  font-weight: 600

.activeRectangle
  width: 6px
  height: 6px
  background: var(--danger)
  border-radius: 50%
  margin-left: 5px

.icon
  margin-right: 5px
  fill: var(--text-secondary, #5D5D5D)
  height: 20px
  width: 20px

.showMoreBtn
  color: #00A9D0
  font-size: 15px
  font-weight: 500
