.root
  position: fixed
  width: 250px
  z-index: 100
  border-radius: 10px
  box-shadow: 0px 15px 25px 2px rgba(0, 0, 0, 0.65),

.desktop
  top: 8%
  right: 2%

.mobile
  bottom: 8%
  right: 5%

.profileList
  max-height: 70vh
  margin: 0
  padding: 0
  border-radius: 10px
  list-style-type: none
  background-color: var(--bg-primary, #fff)
  border: 1px solid var(--bg-secondary)
  overflow-y: auto

.profileLink
  display: flex
  align-items: center
  gap: 15px
  padding: 10px 20px,
  cursor: pointer,
  font-size: 15px
  color: var(--text-secondary, #5D5D5D)

  &:hover
    cursor: pointer
    background-color: var(--bg-secondary, #E9E9E9)
