.root
    @media (min-width: 768px)
        top: 85px !important

.toast
    padding: 15px !important
    border-radius: 10px !important
    border: 1px solid var(--bg-secondary, #E9E9E9) !important
    background: var(--bg-primary, #fff) !important

    button
        color: var(--text-primary) !important
