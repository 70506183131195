@import 'styles/_variables'
@import 'styles/_mixins'

.root
  position: relative
  display: flex
  flex-direction: column
  width: 100%

.textarea
  border-radius: 10px
  border: 2px solid var(--bg-secondary, #fbfbfb)
  background-color: var(--bg-primary, #fff)
  font-size: 15px
  color: var(--text-primary, #222)
  min-height: 120px
  padding: 15px
  width: 100%
  resize: none
  outline: none
  font: inherit

  @include placeholder
    color: var(--text-secondary, #5D5D5D)
    font-size: 15px
    opacity: 0.7

.textareaDisabled
  @include placeholder
    opacity: 0.5 !important

.textareaWithCounter
  padding-right: 60px !important

.symbols
  position: absolute
  top: 12px
  right: 20px
  font-size: 10px
  line-height: 1.2
  color: var(--text-secondary, #5D5D5D)

.symbolMax
  color: var(--text-secondary, #5D5D5D)

.errorText
  position: absolute
  bottom: -20px
  left: 0
  line-height: 1.2
  color: var(--danger, #FF0000)
  font-size: 12px

  &.noSymbolsLeftText
    bottom: 0
