*
  padding: 0
  margin: 0
  box-sizing: border-box

@import 'reset'
@import 'variables'
@import 'libs'

body, html, #root
  height: 100%
  min-height: 100%
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif
  font-size: 14px
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

body
  background-color: var(--bg-primary-light, #F5F5F5)
  scrollbar-width: thin
  scrollbar-color: #E0E0E0 transparent

.EmojiPickerReact
  background: transparent !important

.EmojiPickerReact.epr-main
  border: none !important

.EmojiPickerReact .epr-category-nav
  padding: 5px

.epr-emoji-category-label
  height: 30px !important
  color: #ececec
  background: var(--bg-primary) !important
