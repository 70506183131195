@import 'styles/_variables'

.root
  height: 100%
  background: var(--bg-primary-light, #F5F5F5)
  padding-top: 60px
  display: flex
  flex-direction: column

.oneColumContainer
  display: flex
  flex-direction: column
  padding: 10px
  max-width: 1060px

.oneColumContent
  position: relative
  flex: 1
  display: flex
  flex-direction: column
  background-color: var(--bg-primary, #ffffff)
  border-radius: 15px

.primaryContainer
  position: relative
  display: flex
  flex-direction: column
  padding: 0 10px

.primaryContent
  flex: 1
  display: flex
  flex-direction: column
  padding: 10px 395px 10px 0

  @media screen and (max-width: 1340px)
    padding: 10px 0 10px 0

.sidebar
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 10

.sidebarContainer
  position: relative

.sidebarContent
  display: flex
  flex-direction: column
  position: absolute
  right: 10px
  top: 60px
  margin-bottom: 10px
  padding-top: 10px
  max-width: 385px
  width: 100%
  height: calc( 100vh - 75px )
  z-index: 10
