@import 'styles/_variables'
  
.root
  position: relative
  flex: 1 0 auto
  font-size: 0

.img
  object-fit: contain
  border-radius: 5px
  max-width: 100%
  width: auto !important

.imgBlured
  filter: blur(10px)

.premium
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  text-align: center
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-color: rgba(0,0,0, .4)
  padding: 10px

.premiumTitle
  font-size: 17px
  font-weight: 400
  color: $white
  margin-bottom: 5px

.premiumDescription
  font-size: 12px
  line-height: 1.25
  color: $white
  margin-bottom: 20px

.diamondIcon
  margin-right: 10px

.premiumButton
  max-width: 225px
  text-transform: uppercase
