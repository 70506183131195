.icon
  fill: var(--bg-primary, #fff)

.counter
  margin-left: 5px
  line-height: 1
  color: var(--text-primary-light, #fff)
  font-size: 10px
  font-style: normal
  font-weight: 800
