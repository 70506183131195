.root
  display: flex
  align-items: center

.btn
  width: 45px
  height: 32px
  margin-bottom: 10px
  border: 1px solid var(--bg-secondary)
  padding: 5px
  border-radius: 10px

.input
  margin: 0 10px 10px 0
  padding: 6px 10px
  outline: none
  color: #5D5D5D
  font-size: 15px
  font-weight: 500
  line-height: 18px
  font-weight: 400
  border-radius: 29px
  background: var(--bg-primary-light, #F5F5F5)
  border: 1px solid #E9E9E9

  &::placeholder
    font-size: 15px
    font-weight: 500
    color: #5D5D5D
    opacity: 0.5
