@import 'styles/_variables'

.root
  cursor: pointer
  display: grid
  grid-template-columns: auto 1fr
  grid-column-gap: 10px
  align-items: center
  padding: 10px 20px

.rootTypeNew
  .description
    font-weight: 600

.imageWrapper
  position: relative

.info
  display: flex
  flex-direction: column
  justify-content: center

.title
  display: flex
  align-items: center
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 600

.date
  margin-left: auto
  font-weight: 500

.description
  display: grid
  grid-template-columns: 1fr auto
  grid-column-gap: 10px
  margin-top: 5px
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 400
  transition: all 0.2s ease-in-out
