@import 'styles/_variables'
    
.controls
  display: flex
  align-items: center
  justify-content: center
  gap: 40px
  margin-top: 60px

.likeBtn
  background-color: transparent
  color: $white
  border: 1px solid $white

@media (min-width: 768px)
  .likeBtn,
  .chatBtn
    min-width: 134px

.likeIcon,
.chatIcon
  margin-right: 10px