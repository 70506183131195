@import 'styles/_variables'

.root
  flex: 1
  display: flex
  flex-direction: column

.list
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  margin-bottom: 20px

.addBtn
  border-radius: 5px
  background: var(--bg-primary-light, #F5F5F5)
  color: var(--text-secondary, #5D5D5D)
  font-size: 14px
  font-weight: 500
  min-height: 35px
  height: 35px
  width: auto
  margin: 10px

.box
  flex: 1
  padding: 20px
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-secondary, #5D5D5D)
  font-size: 14px
  font-weight: 500

.icon
  margin-right: 10px
