@import 'styles/_variables'

.list
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto

.listEmpty, .loader
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.listEmptyIcon
  fill: var(--text-secondary, #5D5D5D)
  width: 40px
  height: 40px
  padding: 5px
  border-radius: 50%
  background: var(--bg-secondary, #E9E9E9)
  opacity: 0.5
  transform: rotate(25deg)
  margin-bottom: 15px

.listEmptyText
  color: var(--text-secondary, #5D5D5D)
  text-align: center
  font-size: 15px
  font-weight: 400
