.root
  padding: 20px
  width: 60px
  height: 60px
  display: flex
  align-items: center
  justify-content: center

.icon
  fill: var(--text-secondary, #5D5D5D)

.absolute
  position: absolute
  top: 0
  right: 0
  z-index: 10
