@import 'styles/_variables'

.root
  flex: 1 1
  max-height: 60%
  display: flex
  flex-direction: column
  margin-bottom: 10px
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary, #FFF)
  overflow-y: auto

.header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 20px 0
  margin: 0 20px
  border-bottom: 1px solid var(--bg-secondary, #E9E9E9)

.title
  display: flex
  align-items: center
  color: var(--text-secondary, #5D5D5D)
  font-size: 15px
  font-weight: 600

.activeRectangle
  width: 6px
  height: 6px
  background: var(--danger)
  border-radius: 50%
  margin-left: 5px

.icon
  margin-right: 5px
  fill: var(--text-secondary, #5D5D5D)
  height: 20px
  width: 20px

.showMoreBtn
  color: #00A9D0
  font-size: 15px
  font-weight: 500

.list
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto

.listEmpty, .loader
  flex: 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.listEmptyText
  color: var(--text-secondary, #5D5D5D)
  text-align: center
  font-size: 15px
  font-weight: 400
