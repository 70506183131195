.root
  position: relative
  display: flex
  align-items: center
  padding: 5px 8px
  color: #707070
  font-size: 14px
  font-weight: 500
  border-radius: 34px
  svg
    width: 20px
    height: 20px

.text
  margin-left: 5px
  border-radius: 34px
  @media screen and (max-width: 345px)
    display: none

.disabled
  opacity: 0.5
