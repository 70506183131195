@import 'styles/_variables'

.root
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 20px

.centeredBlock
  display: flex
  justify-content: center
  align-items: center
  height: 100%

.profileHeader
  display: flex

.connectionStatusBadge
  margin-bottom: 5px

.wrapper
  display: flex
  flex-direction: column

.profileHeaderBox
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: flex-start
  margin-bottom: 20px

.avatar
  margin-right: 10px
  border-radius: 10px

.onlineBadge
  width: auto
  padding: 2px 5px
  color: var(--text-primary-light, #fff)
  background-color: #399541
  border-radius: 57px

.contact
  display: flex
  align-items: center
  gap: 5px

.name
  font-size: 20px
  font-weight: 500
  color: var(--text-primary, #222)

.match
  color: var(--accent)

.country
  color: var(--text-secondary, #5D5D5D)
  font-size: 15px

.label
  padding: 0

  & + .label
    margin-top: 20px

.tagList
  list-style: none

.matchIcon
  min-width: 14px

.userDeleted
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-secondary, #5D5D5D)
  font-size: 15px
  font-weight: 500

.ulid
  font-size: 14px
