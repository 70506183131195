@import 'styles/_variables'

.root
  position: fixed
  top: 140px
  left: 80px
  max-width: 250px
  padding: 10px
  max-height: calc(100vh - 300px)
  display: flex
  flex-direction: column
  overflow-y: auto

.mainRoot
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 85%
  min-height: 60%
  max-height: calc(100vh - 100px)
  margin: 0
  padding: 15px

  @media screen and (min-width: 768px)
    width: 505px
    max-height: 675px
    padding: 20px 25px
