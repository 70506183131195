@mixin placeholder
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input'

  &[placeholder]
    text-overflow: ellipsis

    @each $placeholder in $placeholders
      &:#{$placeholder}-placeholder
        text-overflow: ellipsis
        font-weight: 400
        opacity: 1
        transition: color 0.3s ease
        @content
