.root
  max-width: 650px
  background: var(--bg-primary-light)

.content
  position: relative
  padding: 20px
  display: flex
  flex-direction: column
  align-items: center

.title
  margin-top: 30px
  margin-bottom: 30px
  color: var(--text-primary, #222)
  font-size: 21px
  font-style: normal
  font-weight: 600
  line-height: normal

.track
  padding: 0 15px
  margin-bottom: 30px
  width: 100%
  display: flex
  align-items: center
  justify-content: center

  svg
    fill: var(--accent)
    stroke: var(--accent)

.avatar
  display: flex
  border-radius: 50%
  padding: 2px
  border: 1px dashed var(--text-secondary, #5D5D5D)

.presentInfo
  margin-bottom: 30px
  background: var(--bg-primary)
  display: flex
  flex-direction: column
  align-items: center
  padding: 20px 50px
  border-radius: 10px

.presentImg
  margin-bottom: 5px
  height: 95px
  object-fit: contain

.presentName
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 600

.deliveryDate
  margin-bottom: 30px
  color: var(--text-secondary, #5D5D5D)
  font-size: 15px
  font-weight: 400

.btns
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-column-gap: 20px

.declineBtn
  border: 1px solid var(--danger, #F00)
  color: var(--danger, #F00)
  font-size: 16px
  font-weight: 500

.confirmBtn
  color: var(--text-primary-light)
  font-size: 16px
  font-weight: 500
