@import 'styles/_variables'
@import 'styles/_mixins'

.root
  display: flex
  flex-direction: column

.popup
  max-width: 600px
  width: 100%
  padding: 0

.popupBody
  padding: 25px
  overflow-y: auto

.title
  margin-bottom: 20px
  color: var(--text-primary, #000)
  font-size: 15px
  line-height: 20px
  font-weight: 600

.textarea
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  padding: 20px
  font-size: 15px
  min-height: 200px

  @include placeholder
    font-size: 15px
    color: var(--text-secondary, #5D5D5D)

.form
  display: flex
  flex-direction: column

.actionsContainer
  margin-bottom: 20px
  position: relative

.actionBtns
  display: flex
  align-items: center

.textareaContainer
  margin-bottom: 20px
  position: relative

.btn
  height: 50px
  border-radius: 10px
  color: var(--text-primary-light, #fff)
  font-size: 16px
  font-weight: 600

.assetPopup
  margin-bottom: 5px
  height: 270px
  max-height: 270px
