.root
  display: flex
  flex-direction: column

.item
  display: flex
  flex-direction: column
  margin-bottom: 12px
  padding: 5px 10px
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary-light, #F5F5F5)

.question
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 400

.answer
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 600
