.enter, .appear
  opacity: 0

.enterActive, .appearActive
  opacity: 1
  transition: opacity 200ms ease-in-out

.exit
  opacity: 1

.exitActive
  opacity: 0
  transition: opacity 200ms ease-in-out
