.lastActivitiLabel
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  color: var(--text-primary, #000)

.labelBackground
  width: fit-content
  padding: 5px 10px
  border-radius: 10px
  background-color: var(--bg-primary-light, #F5F5F5)

.boldText
  font-weight: 500
  color: var(--text-primary, #000)
