.root
  width: fit-content
  display: flex
  align-items: center

.userAvatar
  position: absolute
  z-index: 1
  background-color: var(--bg-secondary, #E9E9E9)
  border-radius: 50%
  border: 2px solid var(--bg-primary, #FFF)

.contactAvatar
  z-index: 2
  position: relative
  background-color: var(--bg-secondary, #E9E9E9)
  border-radius: 50%

.contactWithUser
  border: 2px solid var(--bg-primary, #FFF)

.badge
  z-index: 1
  position: absolute
  bottom: 0
  right: 0
  width: 39%
  height: 39%
  border: 2px solid var(--bg-primary, #FFF)
  border-radius: 50%

.offline
  z-index: 2
  position: absolute
  bottom: 0
  right: 0
  background: #909090
  border: 3px solid var(--bg-primary, #FFF)
  border-radius: 50%
  width: 33%
  height: 33%
  max-width: 18px
  max-height: 18px

.online
  background: #399541
