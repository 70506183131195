
.popupContent
  position: absolute
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary, #fff)
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.20)
  display: flex
  flex-direction: column
  width: 100%
  max-width: 450px
  height: 44vh
  max-height: 600px
