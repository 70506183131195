@import 'styles/_variables'
@import 'styles/_mixins'

.root
  display: flex
  gap: 10px
  padding: 20px 20px 10px 20px
  border-bottom: 1px solid var(--bg-primary-light, #F5F5F5)

.container
  flex: 1
  display: flex
  flex-direction: column

.header
  margin-bottom: 10px
  display: flex
  align-items: center
  justify-content: space-between

.title
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 600

.date
  color: #8D8D8D
  font-size: 15px
  font-weight: 400

.note
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 400

.noteInput
  padding: 10px !important
  height: auto !important
  min-height: 112px !important
  font-size: 15px !important
  border: 1px solid var(--bg-secondary, #E9E9E9) !important

  @include placeholder
    font-size: 15px !important

.arrows
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 10px

.arrowBtn
  padding: 15px 2px
  border-radius: 5px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  width: 25px

.arrowUp
  width: 11px
  transform: rotate(90deg)

.arrowDown
  width: 11px
  transform: rotate(-90deg)

.noteContainer
  position: relative
  display: grid
  grid-template-columns: 1fr 20px
  grid-column-gap: 3px
  align-items: center

.form
  display: flex
  flex-direction: column

.formWrapper
  display: grid
  grid-template-columns: auto 1fr
  grid-gap: 10px

.saveEdit
  margin: 10px 0 0 auto
  min-width: 32px
  color: var(--accent,#00A9D0)
  font-size: 15px
  line-height: 20px
  font-weight: 500

.icon
  circle
    stroke: var(--secondary, #8D8D8D)
  path
    fill: var(--secondary, #8D8D8D)

.iconEdit
  stroke: var(--secondary, #8D8D8D)
