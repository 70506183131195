@import 'styles/_variables'

.root
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 11
  height: 60px
  display: flex
  align-items: center
  background: var(--bg-primary, #FFFFFF)
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.10)

.container
  position: relative
  display: flex
  align-items: center

.navLinksLeft
  margin-left: auto
  display: flex
  align-items: center

.navLinkLeft, .navLinkRight
  padding: 10px
  position: relative
  display: flex
  align-items: center
  text-align: center
  font-size: 16px
  font-weight: 500
  color: var(--text-secondary, #5D5D5D)

.navLinkLeft
  padding-right: 20px
  & + &
    margin-left: 15px

  @media screen and (max-width: 1024px)
    display: flex
    flex-direction: column
    font-size: 14px

    .rectangleLeftLink
      right: 50%
      transform: translateX(25px)

    .navLinkLeftIcon
      width: 30px
      height: 30px
      padding: 4px
      margin: 0
      background: var(--bg-secondary, #E9E9E9)
      border-radius: 50%
    p
      margin-top: 5px

.navLinkLeftIcon
  width: 20px
  height: 20px
  margin-right: 3px

.navLinksRight
  min-width: 50px
  display: flex
  align-items: center
  justify-content: center

.navLinkRightIcon
  width: 30px
  height: 30px
  padding: 4px
  background: var(--bg-secondary, #E9E9E9)
  border-radius: 50%

.defaultProfile
  position: relative
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  &:hover
    .profileError
      display: flex

.profileError
  display: none
  align-items: center
  position: fixed
  top: 50px
  right: 40px
  z-index: 20
  white-space: nowrap
  padding: 10px 20px
  border-radius: 10px
  background: var(--bg-primary, #FFF)
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15)

  span
    margin-right: 5px
    min-width: 16px
    height: 16px
    border-radius: 16px
    background-color: var(--danger)
    display: flex
    align-items: center
    justify-content: center
    color: var(--text-primary-light, #fff)

.divider
  width: 1px
  height: calc(100% - 40px)
  margin: 20px 40px
  background: var(--bg-secondary, #E9E9E9)

.rectangleLeftLink
  position: absolute
  margin-left: 3px
  top: 6px
  right: 0
  padding: 0 2px
  min-width: 16px
  height: 16px
  border-radius: 16px
  background-color: var(--danger)
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-primary-light, #fff)
  text-align: center
  font-size: 10px
  line-height: 1
  font-weight: 500

.dot
  position: absolute
  top: 8px
  right: 8px
  padding: 0 2px
  min-width: 8px
  height: 8px
  border-radius: 16px
  background-color: var(--danger)
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-primary-light, #fff)
  text-align: center
  font-size: 10px
  line-height: 1
  font-weight: 500

.navLinkActive
  color: var(--accent, #00A9D0)

  .navLinkLeftIcon
    fill: var(--accent, #00A9D0)
    @media screen and (max-width: 1024px)
      background: var(--accent-light, EBFBFF)

  .navLinkRightIcon
    fill: var(--accent, #00A9D0)
    background: var(--accent-light, EBFBFF)

.navLinkLeftIcon, .navLinkRightIcon
  fill: var(--text-secondary, #5D5D5D)
