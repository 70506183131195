@import 'styles/_variables'

.root
  position: fixed
  bottom: 0
  left: 0
  z-index: 20
  width: 100%
  padding: 8px 0
  background: var(--bg-primary, #FFFFFF)
  filter: drop-shadow(0px -4px 12px rgba(0, 0, 0, 0.15))
  display: grid
  grid-template-columns: repeat(5, 1fr)
  border-radius: 12px 12px 0 0

.navLink
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  text-align: center
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: normal
  color: var(--text-secondary, #5D5D5D)

.navLinkWithMargin
  .navLinkIcon
    height: 30px
    margin-top: -2px
    margin-bottom: 2px

.navLinkIcon
  width: 30px
  height: 30px

.rectangle
  position: absolute
  top: 0
  left: calc(50% + 5px)
  width: 10px
  height: 10px
  border-radius: 50%
  background-color: var(--danger)

.rectangleWithCounter
  position: absolute
  top: -2px
  left: calc(50% + 3px)
  display: flex
  align-items: center
  justify-content: center
  padding: 0 3px
  border-radius: 18px
  min-width: 18px
  height: 18px
  border: 2px solid var(--bg-primary, #ffffff)
  background-color: var(--danger)
  color: var(--text-primary-light, #fff)
  font-size: 8px
  line-height: 1
  font-weight: 600

.navLinkActive
  color: var(--accent, #00A9D0)

  .navLinkIcon
    fill: var(--accent, #00A9D0)

.navLinkIcon
  fill: var(--text-secondary, #5D5D5D)
