.root
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0, 0, 0, .5)
  z-index: 100

.rootTypeMobileStyle
  align-items: flex-end

  .popup
    max-width: 100%
    flex-grow: 1
    margin: 0
    border-radius: 20px 20px 0 0

.blured
  filter: blur(10px)

.popup
  position: relative
  margin: 0 20px
  border-radius: 10px
  background-color: var(--bg-primary, #fff)
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.2)
  border: 1px solid var(--bg-secondary, #E9E9E9)
  width: 100%
  max-width: 400px

.popupTypeIndented
  padding: 20px

.body
  width: 100%
  height: 100%
  max-height: calc(100vh - 40px)
  border-radius: 10px
  overflow-y: auto

  @media screen and (min-width: 768px)
    max-height: calc(100vh - 100px)

.bodyTypeIndented
  max-height: calc(100vh - 100px)

  @media screen and (min-width: 768px)
    max-height: calc(100vh - 200px)

.closeBtn
  position: absolute
  top: 10px
  right: 10px
  width: 40px
  height: 40px
  display: flex
  justify-content: center
  align-items: center
  z-index: 1

.closeIcon
  width: 20px
  height: 20px
  fill: var(--text-primary)
