@import 'styles/_variables'

.root
  padding: 0 15px

  & + &
    margin-top: 30px

.title
  margin-bottom: 10px
  color: var(--text-primary, #222)
  font-size: 18px
  font-weight: 500
  line-height: 20px
