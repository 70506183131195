@import 'styles/_variables'

.header
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px
  font-size: 15px
  font-weight: 600
  color: #222

.closeButton
  width: auto
  height: auto
  padding: 15px 0 15px 20px

  & svg
    width: 20px
    height: 20px

.profileList
  display: flex
  flex-direction: column
  gap: 10px

.profileItem
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer
  gap: 10px
  padding: 10px
  border-radius: 10px
  background-color: var(--bg-primary-light)

.flexContainer
  display: flex
  align-items: center
  gap: 10px

.arrowIcon
  display: flex
  justify-content: center
  align-items: center
  width: 30px
  height: 30px

  & path
    fill: #969696
    transform: rotate(180deg) translate(-10px, -16px)

.name
  display: inline-block
  max-width: 200px
  overflow: hidden
  white-space: nowrap
  font-size: 15px
  font-weight: 500
  text-overflow: ellipsis
  color: var(--text-primary, #222)
