.tag
  display: inline-block
  padding: 5px 10px
  height: 30px
  display: flex
  align-items: center
  justify-content: center
  margin: 0 10px 10px 0
  color: var(--text-primary-dark, #222)
  font-size: 16px
  font-weight: 400
  background-color: #F0F0F0
  border-radius: 28px
