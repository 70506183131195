.root
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 40px 0
  background-color: var(--text-primary-dark, #000)
  z-index: 46

.holder
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 100%
  max-width: 1280px
  margin: 0 auto

.closeBtn
  width: 50px
  height: 50px
  position: absolute
  top: 0
  left: 0
  margin: 5px
  z-index: 11

.photoViewStatusWrapper
  position: absolute
  top: 25px
  right: 25px

.carousel
  width: 100%

.img
  width: 100%
  max-height: 85vh

  @media screen and (min-width: 768px)
    width: auto
    // max-height: 95vh

.prevBtn,
.nextBtn
  position: absolute
  top: 50%
  transform: translateY(-50%)
  width: 100px
  height: 100%
  cursor: pointer
  z-index: 10

.prevBtn
  left: -30px

  @media screen and (max-width: 768px)
    left: -30px

.nextBtn
  right: -30px

  @media screen and (max-width: 768px)
    right: -30px

.counter
  position: fixed
  bottom: 30px
  left: 30px
  z-index: 1

  @media screen and (max-width: 768px)
    bottom: 55px

.bluer
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  backdrop-filter: blur(12.5px)

.privatePopup
  position: absolute
  z-index: 1
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 349px
  padding: 20px
  border-radius: 10px
  background: rgba(255, 255, 255, 0.50)

.privatePopupIcon
  margin-bottom: 10px
  & path
    fill: var(--text-primary-dark, #000)

.privatePopupHeadline
  margin-bottom: 10px
  font-size: 18px
  font-weight: 600

.privatePopupText
  margin-bottom: 10px
  text-align: center
  font-size: 15px
  font-weight: 500

.privatePopupMessageBtn
  width: auto
  height: 40px
  border-radius: 10px
  font-size: 16px
  font-weight: 500

.privatePopupMessageIcon
  fill: var(--bg-primary, #fff)
  margin-right: 5px
