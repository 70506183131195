.root
  display: flex
  flex-direction: column
  cursor: pointer

.text
  color: var(--text-primary, #222)
  font-size: 15px
  font-weight: 400
  line-height: 150%
  word-break: break-word
  white-space: pre-wrap

.readMore
  margin-top: 5px
  color: var(--accent, #00A9D0)
  font-size: 15px
  font-weight: 500
  line-height: 150%
