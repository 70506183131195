.headline
  margin-bottom: 15px
  color: var(--text-primary, #222)
  font-size: 18px
  font-weight: 500

.galleryList
  display: grid
  grid-template-columns: repeat(3, 1fr)
  list-style-type: none
  gap: 2px

.galleryListItem
  overflow: hidden

  &:first-child,
  &:first-child img
    border-top-left-radius: 15px
  &:nth-child(3),
  &:nth-child(3) img
    border-top-right-radius: 15px
  &:nth-child(4),
  &:nth-child(4) img
    border-bottom-left-radius: 15px
  &:nth-child(6),
  &:nth-child(6) img
    border-bottom-right-radius: 15px

.galleryListItemTwoRow
  &:first-child,
  &:first-child img
    border-top-left-radius: 15px
    border-bottom-left-radius: 15px
  &:nth-child(3),
  &:nth-child(3) img
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px

.galleryBox
  background-color: transparent

  & > div
    background: none !important
  & img
    width: 100%
    object-fit: cover
    aspect-ratio: 1 / 1

.privateOverlay
  border-top-right-radius: 15px
