@import 'styles/_variables'

.root
  display: flex
  align-items: center

.content
  margin-left: 15px
  flex: 1 0 auto
  width: 76%

.title
  font-size: 17px
  font-weight: 500
  color: var(--text-primary, #fff)
  margin-bottom: 2px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.subtitle
  font-size: 15px
  line-height: 1.3
  color: var(--text-secondary, #5D5D5D)
  overflow: hidden
  text-overflow: ellipsis
  width: 90%
  white-space: nowrap
