@import 'styles/_variables'

.wrapper
  display: inline-flex
  align-items: center
  padding: 5px 12px
  font-weight: 400
  font-size: 15px
  line-height: 20px
  color: var(--text-primary, #222)
  align-items: center
  margin-bottom: 10px
  margin-right: 10px
  word-break: break-word
  white-space: break-spaces
  border-radius: 29px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary-light, #F5F5F5)

.icon
  display: flex
  margin-right: 8px
  line-height: 1
  align-self: flex-start
  svg
    fill: var(--text-primary, #222)

.bold
  font-weight: 600

.title
  padding-right: 5px
  align-self: flex-start
  white-space: nowrap

.marginBottom0
  margin-bottom: 0
