.title
  padding-left: 15px
  margin-bottom: 15px
  color: var(--text-primary, #222)
  font-size: 18px
  font-weight: 500
  line-height: 20px

.tags
  display: flex
  flex-wrap: wrap

.tag
  padding: 5px 10px
  margin: 0 10px 10px 0
  color: var(--text-primary, #222)
  font-size: 16px
  font-weight: 400
  background-color: #F0F0F0
  border-radius: 28px

  & span
    font-weight: 600
