@import 'styles/_variables'

.root
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  background: var(--bg-primary, #fff)
  width: 100%
  height: 100%
  svg
    width: 10px
    height: 10px

.rootTypeInbox
  background: #D400E3

.rootTypeLike
  background: #FF0000

.rootTypeMessage
  background: var(--accent, #00A9D0)

.rootTypeNewProfile
  background: var(--accent, #00A9D0)

.rootTypePopular
  background: #FF9900
