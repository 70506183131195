@import 'styles/_variables'

.root
  flex: 1 1
  max-height: 60%
  display: flex
  flex-direction: column
  margin-bottom: 10px
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary, #FFF)
  overflow-y: auto

.header
  display: grid
  grid-template-columns: repeat(3, 1fr)

.headerItem
  cursor: pointer
  height: 60px
  display: flex
  align-items: center
  justify-content: center
  color: var(--text-primary)

.headerItemActive
  color: var(--accent, #00A9D0)

.headerTrack
  height: 1px
  background: var(--bg-secondary, #E9E9E9)
  display: flex
  align-items: center

.headerActiveTrack
  width: 33%
  height: 100%
  background: var(--accent, #00A9D0)
  transition: all 0.3s ease-in-out

.headerActiveTrack2
  margin-left: 33%

.headerActiveTrack3
  margin-left: 66%

.content
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  transition: all 0.3s ease-in-out
