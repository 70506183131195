@import 'styles/_variables'

.root
  flex: 1 1
  max-height: 60%
  display: flex
  flex-direction: column
  margin-bottom: 10px
  border-radius: 10px
  border: 1px solid var(--bg-secondary, #E9E9E9)
  background: var(--bg-primary, #FFF)
  overflow-y: auto

.content
  flex: 1
  display: flex
  flex-direction: column
  overflow-y: auto
  transition: all 0.3s ease-in-out
